/* Add these styles to your CSS file */

/* Style the table */
table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd;
  }
  
  /* Style table header */
  table th {
    background-color: #f2f2f2;
    padding: 8px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  /* Style table rows */
  table td {
    padding: 8px;
    border: 1px solid #ddd;
  }
  
  /* Style the Download button */
  .download-button {
    margin-left: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  /* Highlight the button on hover */
  .download-button:hover {
    background-color: #0056b3;
  }

  .dropdown-container {
    width: 500px; /* Set your desired width */
    margin-bottom: 10px; /* Add some spacing between dropdowns and other elements */
  }
  
  .dropdown {
    width: auto;
    max-width: 100%;
  }


  .submit-button {
    background-color: blue;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }

  .download-button {
    background-color: blue;
    color: white;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    border-radius: 3px;
    margin-left: 10px;
  }
  
  .file-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }


  .download-button {
    background-color: blue;
    color: white;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    border-radius: 3px;
    margin-left: 10px;
  }


.field {
  margin-bottom: 16px; /* Adjust the margin as needed for your spacing preferences */
}

/* DatePickerCustom.css */
.react-datepicker-wrapper {
  display: inline-block;
  position: relative;
}

.react-datepicker__input-container {
  display: block;
}

.custom-datepicker {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Style the calendar icon */
.react-datepicker-wrapper .react-datepicker__input-container::after {
  content: "\f073"; /* Font Awesome or custom icon content here */
  font-family: FontAwesome; /* Use the appropriate font family for your custom icon */
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 18px;
  color: #333; /* Icon color */
  cursor: pointer;
}

/* Style the icon on hover or focus */
.react-datepicker-wrapper .react-datepicker__input-container:hover::after,
.react-datepicker-wrapper .react-datepicker__input-container:focus::after {
  color: #007bff; /* Change icon color on hover or focus */
}
.date-picker-container {
  display: flex; /* Use flexbox to arrange children side by side */
}

.date-picker {
  margin-right: 10px; /* Add margin between the date pickers for spacing */
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 24px;
  height: 24px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

  