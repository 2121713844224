body, html {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
}

button {
  margin-left: .2em;
}

.topbox {
  display: flex;
  width: 100%;
  height: 100vh;
}

.left-column, .right-column {
  height: 100vh;
}

.left-column {
  display: flex;
  flex-direction: column;
  width: 555px;
  background-color: #f0f0f0;
}

.top-row, .bottom-row {
  background-color: #d0d0d0; 
}

.top-row {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.top-row h3 {
  margin-bottom: 10px;
}

.bottom-row {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.middle-row {
  flex-grow: 1;
  padding: 10px;
}

.right-aligned-buttons {
  display: flex;
}

.right-column {
  flex-grow: 1;
  background-color: #e9e9e9; 
}

/* form styling */
.form-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f6f6f6;
}

.form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-size: 0.9em; 
}

label {
  font-weight: bold;
  margin-right: 10px;
}

input[type="text"] {
  flex-grow: 1;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-button-container {
  display: flex;
  justify-content: flex-end;
}

/* login page */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Change this to align the content to the left */
  width: 500px; /* Add a fixed width for the container */
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: absolute; /* Set the position property to absolute */
  top: 50%; /* Move the container to the middle vertically */
  left: 50%; /* Move the container to the middle horizontally */
  transform: translate(-50%, -50%); /* Adjust the container's position for precise centering */
  background-color: #f0f0f0;
}

h2 {
  margin-bottom: 20px;
}

.login-button {
  background-color: #007BFF;
  color: #ffffff;
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-button:hover {
  background-color: #0056b3;
}