/* Segmented Button Container */
.segmented-button-field {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  width:50%
}

/* Individual Segmented Buttons */
.segmented-button {
  /* You can set specific widths for each button */
  width: 50%; /* Or specify a width in pixels or other units */
  min-width: 0; /* To allow narrower buttons */
  padding: 10px 20px;
  background-color: #f4f4f4;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

/* Active Segmented Button */
.segmented-button.active {
  background-color: #007bff;
  color: #fff;
  transition: background-color 0.2s ease-in-out;
}

/* Hover Effect */
.segmented-button:hover {
  background-color: #007bff;
  color: #fff;
}
